<template>
  <b-card
    class="statementreceivemethod-edit-wrapper"
  >
    <!-- form -->
    <b-form id="statementreceivemethodForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="收款单ID  statement_receive.receive_id"
            label-for="receive_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="receive_id"
              size="sm"
              v-model="statementreceivemethod.receive_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="收款单编号 statement_receive.receive_no"
            label-for="receive_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="receive_no"
              size="sm"
              v-model="statementreceivemethod.receive_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="收款方式 statement_pay_method"
            label-for="receive_method"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="receive_method"
              size="sm"
              v-model="statementreceivemethod.receive_method"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="收款金额"
            label-for="receive_total"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="receive_total"
              size="sm"
              v-model="statementreceivemethod.receive_total"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="收款账号"
            label-for="receive_account"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="receive_account"
              size="sm"
              v-model="statementreceivemethod.receive_account"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加时间"
            label-for="create_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="create_time"
              size="sm"
              v-model="statementreceivemethod.create_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加人ID"
            label-for="creator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="creator"
              size="sm"
              v-model="statementreceivemethod.creator"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="更新时间"
            label-for="update_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="update_time"
              size="sm"
              v-model="statementreceivemethod.update_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="更新人ID"
            label-for="updator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="updator"
              size="sm"
              v-model="statementreceivemethod.updator"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import statementreceivemethodStore from './statementreceivemethodStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      statementreceivemethod: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('statementreceivemethod')) store.registerModule('statementreceivemethod', statementreceivemethodStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('statementreceivemethod')) store.unregisterModule('statementreceivemethod')
    })

    const edit = function() {
      store.dispatch('statementreceivemethod/edit', {id: this.id}).then(res => {
        this.statementreceivemethod = res.data.data
      })
    }

    const view = function() {
      store.dispatch('statementreceivemethod/view', {id: this.id}).then(res => {
        this.statementreceivemethod = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('statementreceivemethod/save', this.statementreceivemethod).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-${classVar}-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
